import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import WhiteTick from '../../assets/whiteTick.png'
const Plans = () => {
  return (
   <div className="plans-container">
    <div className="blur plans-blur-1"></div>
    <div className="blur plans-blur-2"></div>
    <div className="program-header" style={{gap:'2rem'}}>
        <span className='stroke-text'>Ready to start</span>
        <span>Your Journey</span>
        <span className='stroke-text'>Now Withus</span>        
    </div>
    <div className="plans">
        {plansData.map((planing,i)=>(
            <div className="plan">
                {planing.icon}
                <span>{planing.name}</span>
                <span>$ {planing.price}</span>
                <div className="features">
                    {planing.features.map((featureofplans,i)=>
                    (
                        <div className="feature">
                            <img src={WhiteTick} alt=''></img>
                            <span key={i}>{featureofplans}</span>
                        </div>
                    ))}
                </div>
                <div>
                    <span>
                        See More benfits ->
                    </span>
                </div>
                <button className='btn button-plan'>Join Now</button>

            </div>
        ))}
    </div>
   </div>
  )
}

export default Plans