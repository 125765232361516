import React, { useRef } from 'react'
import './Newsletter.css'
import emailjs from '@emailjs/browser'
const Newsletter = () => {
     const form =useRef
     const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_5zdtgfq', 'template_2rtpfrh', form.current, 'Gck0jiS2J-QFc1ev-')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
   <div id="join-us" className="Join">
    <div className="left-join">
        <hr/>
        <div>
            <span className='stroke-text'>Ready To </span>
            <span>Level Up</span>
            
        </div>
        <div>
            <span>Your body </span>
            <span className='stroke-text'>With Us?</span>
        </div>
    </div>
    <div className="right-join">
         <form  action="" className="email-container" onSubmit={sendEmail}>
            <input type="email" name="user_email" id="" placeholder='Enter your Email address' />
            <button className='btn btn-j'>Join Now</button>
        </form>
    </div>

       
   </div>
  )
}

export default Newsletter