import React from 'react'
import './Hero.css'
import hero_image from"../../assets/hero_image.png"
import hero_image_back from"../../assets/hero_image_back.png"
import Heart from"../../assets/heart.png"
import Calories from"../../assets/calories.png"
import Header from "../Header/Header"
import {motion} from 'framer-motion'

const Hero = () => {
  const transition={type:'spring',duration:3}
  return (
    <div className='Hero'>
      <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header></Header>
            <div className="the-best-ad">
               <motion.div 
               initial={{left:'238px'}}
                whileInView={{left:'8PX'}}
                transition={{...transition,type:'tween'}}>
                
               </motion.div>
                <span>The best fitness club in the town</span>
            </div>
            <div className="shape">
              <div>
                  <span className='stroke-text'>Shape </span>
                  <span>Ideal</span>
              </div>
              <div>
                <span>Ideal Body</span>
              </div>
              <div>
                <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
              </div>
            </div>
            {/* figures */}
            <div className="figures">
              <div>
                <span>+143</span>
                <span>EXPERT COACHES</span>
              </div>
              <div>
                <span>+978</span>
                <span>MEMBERS JOINED</span>
                </div>
              <div>
                <span>50+</span>
                <span>FITNESS PROGRAMS</span>
                </div>
            </div>
            <div className='hero-buttons'>
              <button className='btn'>Get Started</button>
              <button className='btn'>Learn More</button>
            </div>
        </div>
        <div className="right-h">
            <button className='btn'>Join Now</button>
            <motion.div className='heart-rate'
            transition={transition}
            whileInView={{right:'4rem'}}
            initial={{right:'-1rem'}}
            >
              <img src={Heart} alt=''></img>
              <span>Heart Rate</span>
              <span>116 bpm</span>
            </motion.div>
        {/* hero images */}
            <img src={hero_image} alt='' className='hero-image' />
            <motion.img
            transition={transition}
            initial={{right:'3rem'}}
            whileInView={{right:'20rem'}}
             src={hero_image_back} alt='' className='hero-image-back' />
            <motion.div 
            transition={transition}
            initial={{right:'37rem'}}
            whileInView={{right:'28rem'}}
            className="calories">
              <img src={Calories} alt=''  />
              <div>
              <span>Calories burned</span>
              <span>220 kcal</span>
              </div>
            </motion.div>

        </div>
    </div>
  )
}

export default Hero