import React from 'react'
import  './Reasons.css'
import Reasonleftsideimg1 from '../../assets/image1.png'
import Reasonleftsideimg2 from '../../assets/image2.png'
import Reasonleftsideimg3 from '../../assets/image3.png'
import Reasonleftsideimg4 from '../../assets/image4.png'
import tick from '../../assets/tick.png'
import nb from '../../assets/nb.png'
import nike from '../../assets/nike.png'
import adidas from '../../assets/adidas.png'
const Reasons = () => {
  return (
    <div className="Reasons" id='reasons'>
        <div className="left-image-side">
            <img src={Reasonleftsideimg1} alt=''></img>
            <img src={Reasonleftsideimg2} alt=''></img>
            <img src={Reasonleftsideimg3} alt=''></img>
            <img src={Reasonleftsideimg4} alt=''></img>
        </div>
        <div className="right-side">
            <span>SOME REASONS</span>
            <div>
                <span className='stroke-text'>WHY </span>
                <span>CHOOSE US?</span>
            </div>
            <div className='detail-reasons'>
                <div>
                    <img src={tick} alt=""></img>
                    <span>OVER 140+ EXPERT COACHS</span>
                </div>
                <div>
                    <img src={tick} alt=""></img>
                    <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
                </div>
                <div>
                    <img src={tick} alt=""></img>
                    <span>1 FREE PROGRAM FOR NEW MEMBER</span>
                </div>
                <div>
                    <img src={tick} alt=""></img>
                    <span>RELIABLE PARTNERS</span>
                </div>
            </div>
            <span style={{color:"var(--gray)",fontWeight:"normal",}}>Our Partners</span>
            <div className="partners">
                <img src={nike} alt="" />
                <img src={nb} alt="" />
                <img src={adidas} alt="" />
            </div>           
        </div>
    </div>
  )
}

export default Reasons