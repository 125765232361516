import './App.css';
import Hero from './components/Hero/Hero';
import Newsletter from './components/Newsletter/Newsletter';
import Plans from './components/Plans/Plans';
import Programs from './components/Programs/Programs';
import Reasons from './components/Reasons/Reasons'
import Testimonials from './components/Testimonials/Testimonials';
import Footer from './components/Footer/Footer'
// import Faq from './components/Faq/Faq';



function App() {
  return (
    <div className="App">
          {/* <span style={{color: 'white'}}>Subscribe Zainkeepscode</span> */}
         <Hero></Hero>
         <Programs></Programs>
         <Reasons></Reasons>
         <Plans></Plans>
         <Testimonials></Testimonials>
         <Newsletter></Newsletter>
         <Footer></Footer>
         {/* <Faq></Faq> */}
    </div>
  );
}

export default App;
