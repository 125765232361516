import React from 'react'
import github from '../../assets/github.png'
import instagram from '../../assets/instagram.png'
import linkedIn from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'
import './Footer.css'
const Footer = () => {
  return (
    <div className='Footer-container'>
        <hr/>
        <div className="footer">
        <div className='social-links'>
                <img src={github} alt="" />
                <img src={linkedIn} alt="" />
                <img src={instagram} alt="" />
            </div>
            <div className="logo-footer">
                <img src={Logo} alt="" />
            </div>
        </div>
        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
    </div>
  )
}

export default Footer