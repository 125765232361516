
import React from 'react'
import './Programs.css'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Programs = () => {
  return (
    <div id="programs" className="Programs">
        {/* programs header */}
        <div className="programs-header">
            <span className="stroke-text">Explore Our</span>
            <span>Programs</span>
            <span className="stroke-text">To Shape You</span>
        </div>
        <div className="program-categories">
           {programsData.map((myprogram)=>(
            <div className="category">
                {myprogram.image}
                <span>{myprogram.heading}</span>
                <span>{myprogram.details}</span>
                <div className='join-now'>
                    <span>Join Now</span>
                    <img src={RightArrow} alt="" />
                </div>
            </div>
           ))} 
        </div>
    </div>
  ) 
}

export default Programs